import shopCategoriesSchema from './shopCategory'
import shopProduct from './shopProduct'
import { LANGS_IRI } from '../_lib/langs'

const schema = {
  endpoint: '/api/shop_promotions',
  resource: {
    definition: '#ShopPromotion',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {
            validate: ['required'],
          },
        },
      },
      rate: {
        description: 'T_MODULE_SHOP_DISCOUNT_CODE_RATE',
        validate: ['required'],
        defaultValue: 0,
      },
      showDiscountAlert: {
        type: 'alert',
        description:
          'Gdy produkt posiada zniżkę kwotową, inne promocje procentowe będą ignorowane dla tego produktu',
        variant: 'filled',
        severity: 'info',
      },
      discountFloatPrice: {
        type: 'number',
        description: 'T_MODULE_SHOP_PROMOTION_DISCOUNT_PRICE',
        hint: 'T_MODULE_SHOP_PROMOTION_DISCOUNT_PRICE_HINT',
        validate: ['money'],
        defaultValue: 0,
      },
      freeShipping: {
        description: 'T_MODULE_SHOP_PROMOTION_FREE_SHIPPING',
      },
      startDate: {
        type: 'date',
        description: 'T_MODULE_SHOP_DISCOUNT_CODE_START_DATE',
        validate: ['required'],
      },
      endDate: {
        type: 'date',
        description: 'T_MODULE_SHOP_DISCOUNT_CODE_END_DATE',
        validate: ['required'],
      },
      categories: {
        //type: 'autoComplete',
        type: 'multipleResource',
        titleAccessor: `translations.${LANGS_IRI.PL}.title`,
        //titleAccessor: `uuid`,
        endpoint: `${shopCategoriesSchema.endpoint}?pagination=false`,
        placeholder: 'Categories',
        label: 'Categories',
        multiple: true,
      },
      products: {
        //type: 'autoComplete',
        type: 'multipleResource',
        titleAccessor: `nativeTitle`,
        //titleAccessor: `uuid`,
        endpoint: `${shopProduct.endpointSelect}?pagination=false`,
        placeholder: 'Categories',
        label: 'Products',
        multiple: true,
      },
      // todo categories autocomplete
      // todo products autocomplete
      stat: {
        description: 'T_GENERAL_PUBLICATION',
      },
    },
  },
}

export default schema
